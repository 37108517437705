const domainReducer = (url) => {
  switch (url) {
    case "commondirt.com":
      return "Common Dirt";

    case "sharingsoil.com":
      return "Sharing Soil";

    case "localhost":
      return "Sharing Soil";

    default:
      return "No Title";
  }
};

export const useDomain = () => {
  const url = window.location.hostname;
  const title = domainReducer(window.location.hostname);

  return { title, url };
};
