import { useDomain } from "../hooks/useDomain";

export default function Footer() {
  const { url, title } = useDomain();
  return (
    <div className="text-center m-3">
      <p>
        {title}{" "}
        <a className="link-light text-decoration-none" href="https://ignite.me">
          &copy;Ignite Channel
        </a>
      </p>
    </div>
  );
}
